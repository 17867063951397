import BlockContent from '@sanity/block-content-to-react'
import serializers from '../serializers'

function Person({ person }) {
  return (
    <div>
      <p class="border-b-[0.1px] border-black pb-1">
        Kursholder: {person.name}
      </p>
      <BlockContent blocks={person.bio} serializers={serializers} />
      <img
        className="w-full h-full md:w-1/2 mt-2 object-cover"
        src={person.image.asset.url}
        alt={person.image.alt || 'An image of the course holder'}
      />
    </div>
  )
}

export default Person
