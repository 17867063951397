import React from 'react'
import { Routes, Route } from 'react-router-dom'
import Layout from './components/Layout'
import About from './pages/About'
import Home from './pages/Home'
import NotFound from './pages/NotFound'
import Project from './pages/Project'
import Members from './pages/Members'
import ActivityOverview from './pages/ActivityOverview'
import Activity from './pages/Activity'

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="prosjekter/:slug" element={<Project />} />
        <Route path="aktiviteter" element={<ActivityOverview />} />
        <Route path="aktiviteter/:slug" element={<Activity />} />
        <Route path="om" element={<About />} />
        <Route path="om/medlemmer" element={<Members />} />
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default App
