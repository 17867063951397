import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { sanity } from '../client'
import BlockContent from '@sanity/block-content-to-react'
import serializers from '../serializers'
import InfoBox from '../components/InfoBox'
import Person from '../components/Person'

function Activity() {
  const { slug } = useParams()
  const query =
    '*[_type == "activity" && slug.current == $slug] {person->{name,bio[],_id,image{asset->{url,alt}}},description[],title,details,mainImage{asset->,alt,caption}}'
  const { data } = useQuery(`${slug}Query`, () => sanity.fetch(query, { slug }))
  if (!data) return <p>loading...</p>
  const { description, title, mainImage, details, person } = data[0]
  return (
    <>
      <div className="w-full flex flex-col-reverse md:flex-row my-4 md:my-8">
        <div className="w-full md:w-1/2">
          <img
            className="w-full mb-8 md:mb-0 aspect-video object-cover"
            alt={mainImage.alt ?? 'A nice picture'}
            src={mainImage?.asset?.url}
          />
        </div>
        <div className="w-full self-end ml-0 md:ml-8 md:w-1/2">
          <h1 className="">{title}</h1>
          {details && <InfoBox details={details} />}
        </div>
      </div>
      <div className="mb-8 md:mb-8 md:mr-8">
        <BlockContent blocks={description} serializers={serializers} />
      </div>
      <div className="w-full md:w-1/2 mb-8 md:mb-0 md:mr-8">
        <Person person={person} />
      </div>
    </>
  )
}

export default Activity
