import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import Footer from './Footer'
import Header from './Header'

function Layout() {
  const { pathname } = useLocation()
  let backgroundClass = 'bg-white'
  if (pathname === '/') {
    backgroundClass = 'bg-home'
  } else if (pathname === '/om') {
    backgroundClass = 'bg-about'
  } else if (pathname === '/om/medlemmer') {
    backgroundClass = 'bg-members'
  } else if (pathname.includes('prosjekter')) {
    backgroundClass = 'bg-project'
  } else if (pathname.includes('aktiviteter')) {
    backgroundClass = 'bg-project'
  }

  return (
    <div className={`${backgroundClass}`}>
      <div className={`container mx-auto px-4 lg:px-8 min-h-screen`}>
        <Header background={backgroundClass} />
        <Outlet />
        <Footer />
      </div>
    </div>
  )
}

export default Layout
