import React from 'react'
import { useQuery } from 'react-query'
import { sanity } from '../client'
import BlockContent from '@sanity/block-content-to-react'
import serializers from '../serializers'

const query =
  "*[_type == 'about']{about[]{..., _type == 'figure' => {alt, asset->{url}}},headerImage{asset->,alt}}"

function About() {
  const { data } = useQuery('aboutPageQery', () => sanity.fetch(query))

  if (!data) return <p>Loading...</p>

  const { about, headerImage } = data[0]
  return (
    <>
      {headerImage && (
        <div className="flex flex-row justify-end my-4 md:my-8">
          <img
            className="w-full md:w-2/5"
            alt={headerImage.alt}
            src={headerImage.asset.url}
          />
        </div>
      )}
      {about && (
        <div className="block-content my-4 md:my-8">
          <h1>Om Herbanists</h1>
          <BlockContent blocks={about} serializers={serializers} />
        </div>
      )}
    </>
  )
}

export default About
