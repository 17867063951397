/* eslint-disable react/jsx-no-comment-textnodes */
import { useState } from 'react' // import state

export default function Header({ background }) {
  const navigation = [
    { title: 'om', url: '/om' },
    { title: 'medlemmer', url: '/om/medlemmer' },
    { title: 'aktiviteter', url: '/aktiviteter' },
  ]
  const [isNavOpen, setIsNavOpen] = useState(false) // initiate isNavOpen state with false
  return (
    <div className="flex items-center justify-between pb-4 md:py-8">
      <a href="/">
        <img
          className="h-20 md:h-40"
          src="/assets/222Herbanists_logo.svg"
          alt="logo"
        />
      </a>
      <nav>
        <section className="flex flex-col items-end">
          {/* <div
            className="space-y-1.5 pt-8"
            onClick={() => setIsNavOpen((prev) => !prev)} // toggle isNavOpen state on click
          >
            <span className="block border-x-2 border-t-2 border-black h-1 w-6"></span>
            <span className="block border-x-2 border-t-2 border-black h-1 w-6"></span>
            <span className="block border-x-2 border-t-2 border-black h-1 w-6"></span>
          </div> */}
          <div className="space-y-1.5">
            <ul className="flex flex-col md:flex-row items-end md:items-center justify-between min-w-[150px]">
              {navigation.map((nav) => (
                <li
                  key={nav.url}
                  className="border-b-[0.1px] border-black my-2 md:ml-8 uppercase"
                >
                  <a className="text-base md:text-lg" href={nav.url}>
                    {nav.title}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className="pt-8 text-xs md:text-base float-right font-source-code">
            forening for urbane kulturlandskap
          </div>

          <div
            className={isNavOpen ? `showMenuNav ${background}` : 'hideMenuNav'}
          >
            <div
              className="container mx-auto absolute h-40 top-0 py-8"
              onClick={() => setIsNavOpen(false)} // change isNavOpen state to false to close the menu
            >
              <svg
                className="h-8 w-8 text-black float-right"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <line x1="18" y1="6" x2="6" y2="18" />
                <line x1="6" y1="6" x2="18" y2="18" />
              </svg>
            </div>
            <ul className="flex flex-col items-center justify-between min-h-[250px]">
              <li className="border-b border-gray-400 my-8 uppercase">
                <a className="text-lg" href="/om">
                  om
                </a>
              </li>
              <li className="border-b border-gray-400 my-8 uppercase">
                <a className="text-lg" href="/om/medlemmer">
                  medlemmer
                </a>
              </li>
            </ul>
          </div>
        </section>
      </nav>
      <style>{`
      .hideMenuNav {
        display: none;
      }
      .showMenuNav {
        display: block;
        position: absolute;
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
        z-index: 10;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
      }
    `}</style>
    </div>
  )
}
