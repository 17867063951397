import React from 'react'
import { useQuery } from 'react-query'
import { sanity, urlFor } from '../client'

const query = "*[_type == 'siteSettings']{mainImage{asset->}}"

function Home() {
  const { data: homepage } = useQuery('homePageQuery', () =>
    sanity.fetch(query)
  )
  if (!homepage) return <p>loading...</p>
  const mainImage = homepage[0]
  return (
    <div>
      <img
        src={urlFor(mainImage.mainImage.asset)}
        alt="Flowers in the meadow"
      />
    </div>
  )
}
export default Home
