const serializers = {
  marks: {
    internalLink: ({ mark, children }) => {
      const { slug = {} } = mark
      const href = `/prosjekter/${slug.current}`
      return (
        <a className="underline" href={href}>
          {children}
        </a>
      )
    },
  },
  types: {
    figure: (props) => {
      const { asset, caption, alt } = props.node
      if (!asset) return null

      return (
        <figure className="my-2 md:my-8">
          <img className="w-full" alt={alt} src={asset.url} />
          {caption && <figcaption className="mb-8">{caption}</figcaption>}
        </figure>
      )
    },
  },
}

export default serializers
