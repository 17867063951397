import { formatTime } from '../client'

export default function InfoBox({ details }) {
  return (
    <ul className="mt-2 md:mt-4">
      <li>
        <span className="font-bold">Tidspunkt:</span>{' '}
        {formatTime(details?.startDate, details?.endDate)}
      </li>
      <li>
        <span className="font-bold">Sted:</span> {details?.place}
      </li>
      <li>
        <span className="font-bold">Pris:</span> {details?.price ?? ' Gratis'}
      </li>
      <li>
        <span className="font-bold">Antall plasser:</span>{' '}
        {details?.capacity ?? ' Ubegrenset'}
      </li>
      {details?.formLink && (
        <li>
          <a
            className="my-2 hover:underline font-bold uppercase"
            target="_blank"
            rel="noreferrer noopener"
            href={details.formLink}
          >
            Meld deg på her
          </a>
        </li>
      )}
    </ul>
  )
}
