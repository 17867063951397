export default function Footer() {
  return (
    <div className="flex flex-col items-center justify-between py-8">
      <img
        className="h-12 md:h-24"
        src="/assets/222Herbanists_logo.svg"
        alt="logo"
      />
      <div className="text-center mt-4">
        <a href="mailto:hello@herbanists.com">hello@herbanists.com</a>
      </div>
    </div>
  )
}
