import BlockContent from '@sanity/block-content-to-react'
import serializers from '../serializers'
export default function MemberCard({ name, image, about }) {
  return (
    <div className="w-full h-auto flex flex-col md:flex-row my-4 md:my-8">
      <img
        className="w-full h-full md:w-1/4 mr-0 md:mr-8 aspect-square object-cover"
        src={image.asset.url}
        alt={image.alt || 'An image of a member'}
      />
      <div className="w-full md:w-3/4 flex flex-col">
        <h1>{name}</h1>
        <BlockContent blocks={about} serializers={serializers} />
      </div>
    </div>
  )
}
