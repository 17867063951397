import { formatTime } from '../client'

export default function ActivityListItem({ activity }) {
  return (
    <div className="border-black border-t-[1px] last:border-b-[1px]">
      <a href={`aktiviteter/${activity.slug.current}`}>
        <div
          key={activity._id}
          className="flex flex-col md:flex-row md:justify-between w-full py-4"
        >
          <span className="w-full md:w-3/5">{activity.title}</span>
          <span className="w-full pt-2 md:pt-0 md:w-1/5">
            {formatTime(activity.details?.startDate, activity.details?.endDate)}
          </span>
          <span className="w-full pt-2 md:pt-0 md:w-1/5">
            {activity?.details?.place}
          </span>
        </div>
      </a>
    </div>
  )
}
