import SanityClient from '@sanity/client'
import imageUrlBuilder from '@sanity/image-url'
import { format, parseISO, isSameDay, isSameMonth } from 'date-fns'
import { nb } from 'date-fns/locale'

export const sanity = SanityClient({
  projectId: 'cw33jhnl', // find this at manage.sanity.io or in your sanity.json
  dataset: 'production', // this is from those question during 'sanity init'
  useCdn: true,
  apiVersion: '2022-01-17',
})

const builder = imageUrlBuilder(sanity)

export function urlFor(source) {
  return builder.image(source)
}

export function formatTime(start, end) {
  if (!start && !end) return false
  if (start && !end) {
    return format(parseISO(start), 'dd. MMM yyyy', { locale: nb })
  }
  if (start && end) {
    if (isSameDay(parseISO(start), parseISO(end))) {
      return format(parseISO(start), 'dd. MMM yyyy', { locale: nb })
    } else {
      if (isSameMonth(parseISO(start), parseISO(end))) {
        const from = format(parseISO(start), 'dd.', { locale: nb })
        const to = format(parseISO(end), 'dd. MMM yyyy', { locale: nb })
        return from + ' - ' + to
      } else {
        const from = format(parseISO(start), 'dd. MMM', { locale: nb })
        const to = format(parseISO(end), 'dd. MMM yyyy', { locale: nb })
        return from + ' - ' + to
      }
    }
  }
}
