/* eslint-disable react/no-unescaped-entities */
import React from 'react'

function NotFound() {
  return (
    <div>
      <h1>We're Sorry</h1>
      <p>We couldn't find the page you were looking for.</p>
    </div>
  )
}

export default NotFound
