import React from 'react'
import { useQuery } from 'react-query'
import { sanity } from '../client'
import MemberCard from '../components/MemberCard'

function Members() {
  const query = `*[_type == "about"]{
    _id,
    image{
      asset->,
      alt
    },
    aboutPerson[]{
      name,
      _key,
      image{
        asset->,
        alt
      },
      about[]{
        ...,
        markDefs[]{
        ...,
        _type == "internalLink" => {
          "slug": @.reference->slug
        }
      }
      }
    }
  }`

  const { data: allMembersData } = useQuery(`allMembersQuery`, () =>
    sanity.fetch(query)
  )
  if (!allMembersData) return <p>loading...</p>
  const { aboutPerson, image, _id } = allMembersData[0]

  return (
    <>
      {image && (
        <div key={_id} className="flex flex-row justify-start my-4 md:my-8">
          <img
            className="w-full md:w-2/5"
            alt={image.alt}
            src={image.asset.url}
          />
        </div>
      )}
      <h1>Medlemmer</h1>
      {aboutPerson &&
        aboutPerson.map((person) => {
          return (
            <MemberCard
              key={person._key}
              name={person.name}
              image={person.image}
              about={person.about}
            />
          )
        })}
    </>
  )
}

export default Members
