import React from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { sanity } from '../client'
import BlockContent from '@sanity/block-content-to-react'
import serializers from '../serializers'
import Factbox from '../components/Factbox'

function Project() {
  const { slug } = useParams()
  const query =
    '*[_type == "project" && slug.current == $slug]{_id,body[]{...,_type == "figure" => {asset->{url},caption,alt}},factbox,mainImage{asset->,alt,caption},title,services[]->{title}}'
  const { data: projectData } = useQuery(`${slug}Query`, () =>
    sanity.fetch(query, { slug })
  )
  if (!projectData) return <p>loading...</p>
  return (
    <>
      {projectData.map(({ _id, title, mainImage, body, factbox }) => {
        return (
          <div key={_id} className="w-full">
            <img
              className="w-full mb-8 aspect-video object-cover"
              alt={mainImage.alt}
              src={mainImage.asset.url}
            />
            <h1>{title}</h1>
            <div className="w-full flex flex-col-reverse md:flex-row">
              <div className="w-full md:w-3/5 mb-8 md:mb-0 md:mr-8">
                <BlockContent blocks={body} serializers={serializers} />
              </div>
              <div className="w-full md:w-2/5">
                <Factbox factbox={factbox} />
              </div>
            </div>
          </div>
        )
      })}
    </>
  )
}

export default Project
