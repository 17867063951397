import { useQuery } from 'react-query'
import { sanity, urlFor } from '../client'
import BlockContent from '@sanity/block-content-to-react'
import serializers from '../serializers'

import ActivityListItem from '../components/ActivityListItem'

function ActivityOverview() {
  const query = `*[_type == "activityOverview" && _id == "ed22654c-5e15-46c4-a60f-a73dfcb30e2d"]{
    _id,
    mainImage,
    description[],
    title,
    "futureActivities": *[_type == "activity" && dateTime(details.endDate) > dateTime(now())] | order(details.startDate asc) {...},
    "pastActivities": *[_type == "activity" && dateTime(details.endDate) < dateTime(now())] | order(details.startDate desc) {...}
  }
    `
  const { data } = useQuery('activityOverviewQuery', () => sanity.fetch(query))
  if (!data) return <p>Loading...</p>

  const { title, mainImage, description, futureActivities, pastActivities } =
    data[0]
  return (
    <>
      <div className="flex flex-col-reverse md:flex-row justify-between my-4 md:my-8">
        {title && description && (
          <div className="w-full md:w-3/5 md:pr-8 my-auto">
            <h1>{title}</h1>
            <BlockContent blocks={description} serializers={serializers} />
          </div>
        )}
        {mainImage && (
          <img
            className="w-full mb-8 md:w-2/5"
            alt={mainImage.alt}
            src={urlFor(mainImage)}
          />
        )}
      </div>
      <div className="mb-8">
        <h1>Kommende aktiviteter</h1>
        {futureActivities &&
          futureActivities.map((activity) => {
            return <ActivityListItem activity={activity} />
          })}
      </div>
      <div className="mb-8">
        <h1>Tidligere aktiviteter</h1>
        {pastActivities &&
          pastActivities.map((activity) => {
            return <ActivityListItem activity={activity} />
          })}
      </div>
    </>
  )
}

export default ActivityOverview
